import { computed, defineComponent, getCurrentInstance, onMounted } from '@nuxtjs/composition-api';

import { getContrastTextColor } from '@/components/animate/BodyBackground/contrast-utils';
export { blockableBlockColorFragment, contentableBlockColorFragment } from '@/graphql/queries/block-color';

const useBlockColor = () => {
  const instance = getCurrentInstance()!;
  const blockColorAttribute = computed(() => (instance.props.data as any).blockColorAttribute);

  /**
   * Wait for component element to be mounted
   * Animations/transition may delay this
   */
  const getComponentEl = (): Promise<HTMLElement> => {
    // Element is probably animating etc.
    if (instance.proxy.$el.nodeName === '#comment')
      return new Promise(resolve => setTimeout(() => getComponentEl().then(resolve), 100));

    return Promise.resolve(instance.proxy.$el as HTMLElement);
  };

  onMounted(async () => {
    if (!blockColorAttribute.value)
      return;

    // Wait for possible transitions etc.
    const element = await getComponentEl();

    element.style.backgroundColor = blockColorAttribute.value?.bgColor;
    element.style.color = blockColorAttribute.value?.textColor;
    element.style.setProperty('--current-color', element.style.color);
    element.style.setProperty('--current-color-inverted', getContrastTextColor(element.style.color));
  });

  return {
    blockColor: blockColorAttribute.value?.bgColor,
    blockTextColor: blockColorAttribute.value?.textColor,
  };
};

export const blockColorMixin = defineComponent({
  setup: () => useBlockColor(),
});
