import { gql } from 'graphql-tag';

export const blockableBlockColorFragment = gql`
  fragment BlockColor on Blockable {
    blockColorAttribute {
      bgColor
      textColor
    }
  }
`;

export const contentableBlockColorFragment = gql`
  fragment ContentableBlockColor on Contentable {
    blockColorAttribute {
      bgColor
      textColor
    }
  }
`;
